import "./contentPage.scss";

import * as React from "react"
import { graphql } from "gatsby";
import { PrismicLocation } from '../../graphql-types'
import { ContentComponent } from "../components/contentComponent";
import Swiper from "swiper";
import { ReactIdSwiperProps, SwiperInstance } from "react-id-swiper";

interface IProps {
  content?: JSX.Element
  isComponent?: boolean
}

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicLocation(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        header_image {
          url
        }
        geolocation {
          latitude
          longitude
        }
        past {
          html
        }
        present {
          html
        }
        speaker_social_media {
          url
          text
          linkTo {
            url
          }
        }
        future {
          html
        }
      }
    }
  }`

const ContentPage: React.FC<{ data: { prismicLocation: PrismicLocation } }> = props => {

  return <ContentComponent isComponent prismicLocation={props.data.prismicLocation} className="content-page" />
}

export default ContentPage